import React from "react";
import Dropzone from "react-dropzone";

import imageCompression from "browser-image-compression";

import Button from "react-bootstrap/Button";
import CardColumns from "react-bootstrap/CardColumns";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";

const apiBase = window.location.origin.startsWith("http://")
  ? "http://localhost:8000"
  : "https://api.dogedreams.ai";

const modelApi = `${apiBase}/ml/doge-detector`;
const maxImages = 8;
const askForFeedback = false;

// eslint-disable-next-line no-unused-vars
const ResultFeedback = (imgLink, result) => (
  <div>
    <Card.Text>Is this correct?</Card.Text>
    <Button variant="success">Yes</Button>
    <Button variant="danger">No</Button>
    <Card.Text style={{ fontStyle: "italic", fontSize: "small" }}>
      Note: This will store the image on our server, so we can update the model.
    </Card.Text>
  </div>
);

export default class dogeDetector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      imgLinks: null,
      predictions: null,
      progress: null,
      progressTimeout: null
    };
    // Wake up the model lambda on load
    this.wake();
  }

  updateProgress = (prevProgress = 0) => {
    let progress = prevProgress + (5 - 4 * Math.floor(prevProgress / 70));
    if (progress > 99) {
      progress = 99;
    }
    this.setState({
      progressTimeout: setTimeout(() => this.updateProgress(progress), 200),
      progress
    });
  };

  clearProgress = () => {
    const { progressTimeout } = this.state;
    if (progressTimeout !== null) {
      window.clearTimeout(progressTimeout);
    }
    this.setState({
      progressTimeout: null,
      progress: null
    });
  };

  wake = () => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${modelApi}/wake`, true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    xhr.onerror = () => {
      this.setState({
        error: "Failed to communication with server"
      });
    };
    xhr.send("{}");
  };

  handle = e => {
    this.processFiles(e.target.files);
  };

  processFiles = files => {
    this.updateProgress();

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
      useWebWorker: true
    };

    Promise.all(
      files.slice(0, maxImages).map(imageFile =>
        imageCompression(imageFile, options).then(compressedImageFile => {
          return compressedImageFile.size < imageFile.size
            ? compressedImageFile
            : imageFile;
        })
      )
    ).then(imgFiles => {
      const imgLinks = imgFiles.map(file => URL.createObjectURL(file));
      const prevImgLinks = this.state.imgLinks;
      this.setState(
        {
          imgLinks,
          imgFiles,
          predictions: null
        },
        () => {
          if (prevImgLinks !== null) {
            prevImgLinks.map(URL.revokeObjectURL);
          }
          this.sendFiles(imgFiles);
        }
      );
    });
  };

  sendFiles = imgFiles => {
    const uri = `${modelApi}/predict`;
    const xhr = new XMLHttpRequest();
    xhr.open("POST", uri, true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const result = JSON.parse(xhr.responseText);
        this.setState({
          predictions: result.predictions
        });
        this.clearProgress();
      }
    };
    xhr.onerror = error => {
      console.error(error);
      this.setState({
        error: "Failed to communication with server"
      });
      this.clearProgress();
    };
    const images = Array(imgFiles.length);
    const xhrSend = () => {
      xhr.send(
        JSON.stringify({
          images
        })
      );
    };
    let readCount = 0;
    imgFiles.forEach((imgFile, i) => {
      const fileReader = new FileReader();
      const { name, type, size } = imgFile;
      fileReader.onload = readEvent => {
        images[i] = {
          name,
          type,
          size,
          content: readEvent.target.result
        };
        readCount += 1;
        if (readCount === imgFiles.length) {
          xhrSend();
        }
      };
      fileReader.readAsDataURL(imgFile);
    });
  };

  render() {
    const { error, predictions, progress } = this.state;
    let progressDiv = null;
    let resultsDiv = null;
    if (progress !== null) {
      progressDiv = (
        <Row>
          <Col>
            <ProgressBar now={progress} label={`${progress}%`} />
          </Col>
        </Row>
      );
    } else if (predictions !== null) {
      const { imgLinks } = this.state;
      resultsDiv = (
        <CardColumns>
          {predictions.map((result, idx) => {
            return (
              <Card key={imgLinks[idx]} style={{ width: "18rem" }}>
                <Card.Img variant="top" src={imgLinks[idx]} />
                <Card.Body>
                  <Card.Title>
                    {result === "much-doge" ? "Much Doge!" : "No Doge"}
                  </Card.Title>
                  <Card.Text>
                    We have detected that this image&nbsp;
                    {result === "much-doge" ? "DOES" : "DOES NOT"}
                    &nbsp;contain a Doge Meme.
                  </Card.Text>
                  {askForFeedback
                    ? ResultFeedback(imgLinks[idx], result)
                    : null}
                </Card.Body>
              </Card>
            );
          })}
        </CardColumns>
      );
    }

    const headerRow = (
      <Row>
        <Image src="/images/doge-detector-title-image-2.png" fluid />
      </Row>
    );

    if (error !== null) {
      return (
        <Container>
          {headerRow}
          <Row>
            <Image src="/images/down-doge.jpg" fluid />
          </Row>
        </Container>
      );
    }

    return (
      <Container>
        {headerRow}
        {progressDiv}
        {resultsDiv}
        <Row>
          <Col>
            <Dropzone onDrop={files => this.processFiles(files)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <Card.Img
                      className="ht"
                      variant="top"
                      src="/images/upload.png"
                    />
                    <input {...getInputProps()} />
                  </div>
                </section>
              )}
            </Dropzone>
          </Col>
        </Row>
      </Container>
    );
  }
}
